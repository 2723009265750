import { Backdrop, CircularProgress } from '@mui/material';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
const AttendancePage = lazy(() => import('./Modules/course/AttendancePage'));
const FormPage = lazy(() => import('./Modules/course/FormPage'));
const QrPage = lazy(() => import('./Modules/course/QrPage'));
const SuccessPage = lazy(() => import('./Modules/course/SuccessPage'));
const JobFormPage = lazy(() => import('./Modules/jobservice/JobFormPage'));
const JobSuccessPage = lazy(() => import('./Modules/jobservice/JobSuccessPage'));
const EnquiryFormPage = lazy(() => import('./Modules/enquiry/EnquiryFormPage'));
const EnquirySuccessPage = lazy(() => import('./Modules/enquiry/EnquirySuccessPage'));
const RegistrationPage = lazy(() => import('./Modules/lhbbp-registration/RegistrationPage'));
const RegistrationSuccessPage = lazy(() => import('./Modules/lhbbp-registration/RegistrationSuccessPage'));
const PurchaseFormPage = lazy(() => import('./Modules/purchase-requisition/PurchaseFormPage'));
const PurchaseSuccessPage = lazy(() => import('./Modules/purchase-requisition/PurchaseSuccessPage'));
const VerifyEmailPage = lazy(() => import('./Modules/lhbbp-registration/VerifyEmailPage'));
const TermsPage = lazy(() => import('./Modules/course/TermsPage'));
const MembershipFormPage = lazy(() => import('./Modules/membership/MembershipFormPage'));
const MembershipPaymentPage = lazy(() => import('./Modules/membership/MembershipPaymentPage'));
const MembershipFormSuccessPage = lazy(() => import('./Modules/membership/MembershipFormSuccessPage'));

const App = () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 50000 }} open={true}>
            <CircularProgress color='inherit' />
          </Backdrop>
        }
      >
        {process.env.REACT_APP_BUILD_MODE === 'demo' && (
          <Routes>
            {/* course */}
            <Route path='/' element={<FormPage />} />
            <Route path='/qrcode' element={<QrPage />} />
            <Route path='/attendance/:id' element={<AttendancePage />} />
            <Route path='/success' element={<SuccessPage />} />
            <Route path='/terms' element={<TermsPage />} />

            {/* job service */}
            <Route path='/jobservice' element={<JobFormPage />} />
            <Route path='/jobsuccess' element={<JobSuccessPage />} />

            {/* LHBBP enqury */}
            <Route path='/enquiry' element={<EnquiryFormPage />} />
            <Route path='/enquiry-success' element={<EnquirySuccessPage />} />

            {/* LHBBP registration */}
            <Route path='/registration' element={<RegistrationPage />} />
            <Route path='/registration-success' element={<RegistrationSuccessPage />} />
            <Route path='/verification' element={<VerifyEmailPage />} />

            {/* Purchase requisition */}
            <Route path='/purchase-requisition' element={<PurchaseFormPage />} />
            <Route path='/purchase-requisition-success' element={<PurchaseSuccessPage />} />

            {/* Membership Form */}
            <Route path='/membership' element={<MembershipFormPage />} />
            <Route path='/membership-payment' element={<MembershipPaymentPage />} />
            <Route path='/membership-success' element={<MembershipFormSuccessPage />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === 'sgsl' && (
          <Routes>
            <Route path='/' element={<FormPage />} />
            <Route path='/qrcode' element={<QrPage />} />
            <Route path='/attendance/:id' element={<AttendancePage />} />
            <Route path='/success' element={<SuccessPage />} />
            <Route path='/terms' element={<TermsPage />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === 'lhbbp' && (
          <Routes>
            <Route path='/' element={<EnquiryFormPage />} />
            <Route path='/enquiry-success' element={<EnquirySuccessPage />} />
            <Route path='/registration' element={<RegistrationPage />} />
            <Route path='/registration-success' element={<RegistrationSuccessPage />} />
            <Route path='/verification' element={<VerifyEmailPage />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === 'jobservice' && (
          <Routes>
            <Route path='/' element={<JobFormPage />} />
            <Route path='/jobsuccess' element={<JobSuccessPage />} />
          </Routes>
        )}
        {process.env.REACT_APP_BUILD_MODE === 'membership' && (
          <Routes>
            <Route path='/' element={<MembershipFormPage />} />
            <Route path='/membership-payment' element={<MembershipPaymentPage />} />
            <Route path='/membership-success' element={<MembershipFormSuccessPage />} />
          </Routes>
        )}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
